<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field v-model="detail.id" label="Id" disabled></v-text-field>
        <v-autocomplete v-model="detail.modeId" label="Mode" :counter="max" :items="gameType" item-value="key"
          item-text="value" placeholder="Select..."></v-autocomplete>
        <v-text-field v-model="detail.order" label="Order" hide-details></v-text-field>
      </v-flex>
      <v-btn color="success" @click="update">Update</v-btn>
    </v-layout>
  </v-container>
</template>
<script>

import gameType from "@/lib/gameType";
import router from "@/router";
export default {
  data: () => ({
    detail: {},
    gameType: gameType,
    alert: false,
    max: 120,
    menu: false,
    modal: false,
    menu2: false,
  }),

  methods: {
    async update() {
      await this.$store.dispatch("nowTrends/update", this.detail);
      router.push("/nowTrend");
    },
    async initialize() {
      if (this.$route.params.id) {
        await this.$store.dispatch("nowTrends/getById", this.$route.params.id);
        this.detail = this.$store.state.nowTrends.detail;
        // eslint-disable-next-line no-console
        console.log(this.detail);
      }
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  async mounted() {
    await this.initialize();
    // eslint-disable-next-line no-console
    console.log(this.detail);
  },
};
</script>
